svg {
    font-size: 24px;
    line-height: 1em;
 }
 
 .user-icon {
    font-size: 25px;
    color: #c4a708;
 }

 .restaurant-icon {
   font-size: 25px;
   color: #ffffff;
}
/* Green: #059666 Red: #960f05   */