.yelp-info-container {
    display: inline-block;
    text-align: center;
    align-items: center;
    width: 100%;
}

.yelp-info-upper-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.yelp-logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.yelp-logo-container {
    width: 10%;
    height: 10%;
    align-items: center;
    margin: 1%;
}

.yelp-stars-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.yelp-stars-container {
    width: 20%;
    padding: 1%;
    height: 8%;
    float: left;
    margin: 1%;
}

.yelp-after-stars-info-container {
    text-align: center;
    font-size: 175%;
    padding: 1%;
    padding-top: 0%;
    float: left;
    width: 98%;
}

.yelp-catergories-container {
    font-size: 250%;
    text-align: center;
    padding: 3%;
    font-weight: bold;
}

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1000px) {

    .yelp-logo-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }
    
    .yelp-logo-container {
        width: 20%;
        height: 15%;
        float: left;
        margin: 1%;
    }
    
    .yelp-stars-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }
    
    .yelp-stars-container {
        width: 30%;
        height: 20%;
        float: left;
        margin: 2%;
    }

    .yelp-after-stars-info-container {
        width: 100%;
        padding: 3%;
        font-size: 325%;
    }
  
  }