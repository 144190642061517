@import url(//fonts.googleapis.com/css?family=Anton);
.navbar-container {
    background: #000000;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.logo {
    height: 5.5vh;
}

.navbar-title {
    color: #ffffff;
    font-family: Anton;
    font-size:200%;
}

@media screen and (max-width: 1000px) {
    .navbar-title {
        color: #ffffff;
        font-family: Anton;
        font-size:300%;
    }
    .logo {
        height: 3.4vh;
    }
}