.error-overlay-container {
    position: fixed;
    top: 7vh;
    width: 100%;
    height: 4vh;
    background-color: red;
    opacity: 80%;
}

.error-overlay-message {
    color: white;
    padding: 1vh;
}

.error-icon {
    padding: 0.5vh;
    float: left;
}

@media only screen and (max-width: 1000px) {

    .error-overlay-container {
        position: fixed;
        top: 7vh;
        width: 100%;
        height: 8vh;
        background-color: red;
        opacity: 80%;
    }
    
    .error-overlay-message {
        color: white;
        padding: 1vh;
        font-size: 50px;
    }
    
    .error-icon {
        padding: 1vh;
        float: left;
        font-size: 100px;
    }

}