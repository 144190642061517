.selection-area-container {
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(54, 54, 54) 100%);
    width: 25%;
    position: absolute;
    top: 8.9%;
    right: 65px;
    border: 3px solid #000000;
    padding: 10px;
    width: 30%;
    height: 85%;
    margin-left: calc(-50% - (30% / 2));
    border-radius: 2%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1000px) {
    .selection-area-container {
        width: 100%;
        height: 93%;
        border-radius: 0px;
        top: 7%;
        right: 0px;
        padding: 0px;
    }
}