.restaurant-form-flow-label {
    font-size: 250%;
    max-width: 90%;
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin-left: 40px;
}

.restaurant-submit-button-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 1%;
}

.rejectButton {
    float: left;
    bottom: 2%;
}

.approveButton {
    float: right;
    bottom: 2%;
}

.retryButton {
    float: left;
}

.selectButton {
    float: right;
    font-size: 350%;
}

.restaurant-image {
    height: 100%;
    width: auto;
    max-width: 100%;
    overflow: hidden;
}

.restaurant-image-container {
    height: 70%;
    max-height: 90%;
    width: 100%;
}

.loading-indicator-container {
    width: 100%;
    height: 100%;
}

.loading-indicator {
    width: 100%;
}

.restaurant-info-body-container {
    position: relative;
    width: 100%;
    height: 87%;
    overflow-y: auto;
    scrollbar-width: thin;
    text-align: center;
}

.restaurant-form-flow-container {
    height: 100%;
    width: 100%;
}

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1000px) {

    .restaurant-form-flow-label {
        font-size: 500%;
    }

    .restaurant-submit-button-container {
        height: 10%;
    }

    .selectButton {
        font-size: 750%;
    }
    
    .restaurant-info-body-container {
      height: 70%;
    }
  
  }