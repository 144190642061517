.selected-restaurant-form-flow-label {
    font-size: 250%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.selected-restaurant-image {
    height: 70%;
    max-height: 90%;
    width: auto;
    max-width: 100%;
    overflow: hidden;
}

.loading-indicator-container {
    width: 100%;
    height: 100%;
}

.loading-indicator {
    width: 100%;
}

.selected-restaurant-info-body-container {
  width: 100%;
  max-width: 100%;
  height: 85%;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  text-align: center;
  align-items: center;
}

.selected-restaurant-form-flow-container {
    height: 95%;
    width: 100%;
}

.selected-restaurant-submit-button-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 1%;
    display: flex;
    justify-content: space-between;  
}

.rejectButton {
    float: left;
    bottom: 2%;
}

.approveButton {
    float: right;
    bottom: 2%;
}

.reselectButton {
    bottom: 2%;
    margin-left: 3%;
}

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1000px) {

    .selected-restaurant-form-flow-label {
        font-size: 500%;
        text-align: center;
    }

    .selected-restaurant-submit-button-container {
        height: 10%;
    }
    
    .selected-restaurant-image {
        height: 70%;
        max-height: 90%;
        width: auto;
        max-width: 100%;
        overflow: hidden;
    }
    
    .selected-restaurant-info-body-container {
      width: 100%;
      height: 70%;
      overflow-y: auto;
      scrollbar-width: thin;
    }
    
    .selected-restaurant-form-flow-container {
        height: 100%;
        width: 100%;
    }
  
  }