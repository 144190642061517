@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

#selection-flow-container {
    position: relative;
    margin: 1%;
    color: #ffffff;
    font-family: Lato;
    font-size:75%;
    height: 97%;
}
