.form-flow-container {
    position: relative;
    height: 98%;
    width: 100%;
}

.form-submit-button-container {
    position: relative;
    width: 100%;
    height: 10%;
    bottom: 1%;
}

.form-flow-body-container {
    height: 96%;
    width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1000px) {
    .form-submit-button-container {
        position: relative;
        width: 100%;
        height: 10%;
        bottom: 4%;
        left: 2%;
    }

}