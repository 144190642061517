@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.form-control-label {
  font-size: 300%;
  text-align: center;
}

.form-control-container {
    position: relative;
    top: 0.25%;
    left: 0%;
    color: #ffffff;
    font-family: Lato;
    font-size:75%;
    width: 100%;
}

.horizontal-slider {
    width: 100%;
    max-width: 100%;
    height: 5vh;
    margin: auto;
  }
  .slider-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 5px solid #4d52de;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }
  .slider-thumb.active {
    background-color: grey;
  }
  .slider-track {
    position: relative;
    background: #ddd;
  }
  .slider-track.slider-track-0 {
    background: #4d52de;
  }
  .horizontal-slider .slider-track {
    top: 20px;
    height: 4px;
  }
  .horizontal-slider .slider-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }

  .slider-value-show {
    font-size: 300%;
    color: white;
    position: relative;
    left: 40%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1000px) {

  .form-control-label {
    font-size: 500%;
  }

  .form-control-container {
    position: relative;
    top: 0.25%;
    left: 0%;
    color: #ffffff;
    font-family: Lato;
    font-size:75%;
    width: 100%;
}

.horizontal-slider {
    width: 100%;
    max-width: 100%;
    height: 5vh;
    margin: auto;
  }
  .slider-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 10px solid #4d52de;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 44%);
  }
  .slider-thumb.active {
    background-color: grey;
  }
  .slider-track {
    position: relative;
    background: #ddd;
  }
  .slider-track.slider-track-0 {
    background: #4d52de;
  }
  .horizontal-slider .slider-track {
    top: 25px;
    height: 8px;
  }
  .horizontal-slider .slider-thumb {
    top: 10px;
    width: 20px;
    outline: none;
    height: 20px;
    line-height: 76px;
  }

  .slider-value-show {
    font-size: 400%;
    color: white;
    position: relative;
    left: 42%;
  }

}